<template>
  <v-app dark>
    <v-navigation-drawer
      v-model="drawer"
      :clipped="true"
      fixed
      app
    >
      <v-list>
        <v-list-item>
          <v-list-item-action>
            <v-checkbox v-model="playersLayer"></v-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Players</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar :clipped-left="true" fixed app>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title v-text="title" />
      <v-spacer />
      <v-btn icon @click.stop="refreshContent">
        <v-icon v-if="!autoUpdate">fa-refresh</v-icon>
      </v-btn>
      <v-btn icon @click.stop="autoUpdate = !autoUpdate">
        <v-icon v-if="!autoUpdate">fa-play</v-icon>
        <v-icon v-else>fa-pause</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
        <MapView />
    </v-main>
    <v-footer :absolute="!fixed" app>
      <span>&copy; {{ new Date().getFullYear() }}</span>
      <v-spacer />
      <span>X: {{coordinates[0]}} - Y: {{coordinates[1]}}</span>
    </v-footer>
  </v-app>
</template>

<script>
import MapView from './components/MapView.vue'
import {mapState} from 'vuex';
export default {
  name: 'App',
  components: {
    MapView
  },
  data() {
    return {
      drawer: false,
      fixed: false,
      title: process.env.VUE_APP_TITLE,
    }
  },
  computed: {
    ...mapState({
      players: state => state.players,
    }),
    autoUpdate: {
      get() {
        return this.$store.state.autoUpdate;
      },
      set(value) {
        if (value) {
          this.$store.dispatch("runAutoUpdate")
        }
        else {
          this.$store.dispatch("stopAutoUpdate")
        }
        this.$store.commit("setAutoUpdate", value);
      }
    },
    coordinates() {
      return this.$store.state.coordinates;
    },
    playersLayer: {
      get() {
        return this.$store.state.playersLayer;
      },
      set(value) {
        this.$store.commit("updatePlayersLayer", value)
      } 
    },
    buildingsLayer: {
      get() {
        return this.$store.state.buildingsLayer;
      },
      set(value) {
        this.$store.commit("updateBuildingsLayer", value)
      } 
    },
    poiLayer: {
      get() {
        return this.$store.state.poiLayer;
      },
      set(value) {
        this.$store.commit("updatePoiLayer", value)
      } 
    },
    travelnetLayer: {
      get() {
        return this.$store.state.travelnetLayer;
      },
      set(value) {
        this.$store.commit("updateTravelnetLayer", value)
      } 
    },
    otherLayer: {
      get() {
        return this.$store.state.otherLayer;
      },
      set(value) {
        this.$store.commit("updateOtherLayer", value)
      } 
    }
  },
  mounted() {
    fetch("players").then(response => response.json().then((data) => {
      this.$store.commit("setPlayers", data);
    }))
  },
  methods: {
    refreshContent() {
      this.$store.dispatch("manualUpdate");
    }
  }
}
</script>

<style>
html { overflow-y: auto !important; }
.v-navigation-drawer {
    z-index: 1001 !important;
}
</style>
