<template>
  <v-container fluid style="height: 100%; padding: 0px">
    <l-map @mousemove="update" ref="map" style="height: 100%" :maxZoom="maxZoom" :zoom="zoom" :center="center" :worldCopyJump="worldCopyJump" :crs="crs" :options="mapOptions">
      <l-tile-layer ref="baseLayer" :url="url" :attribution="attribution" :tms="tms" continuousWorld="false" minZoom=0 :noWrap="noWrap" :options="layerOptions"></l-tile-layer>
      <div v-if="playersLayer">
        <l-marker v-for="p in players" :key="p.name" :lat-lng="flipCoordinates(p.geometry.coordinates)" :icon="iconPlayer">
          <l-popup>{{p.properties.name}}</l-popup>
        </l-marker>
      </div>
    </l-map>
  </v-container>
</template>

<script>
import L from 'leaflet';
import {mapState} from 'vuex';

L.Projection.NoWrap = {
  project(latlng) {
      return new L.Point(latlng.lat, latlng.lng);
  },
  unproject(point) {
      return new L.LatLng(point.x, point.y, true);
  },
  bounds: L.bounds(L.point(-30928.0,30928.0),L.point(30928.0, -30928.0)) 
};
const ownCRS = L.CRS.Direct = L.Util.extend({}, L.CRS, {
  code: 'Direct',
  projection: L.Projection.NoWrap,
  transformation: new L.Transformation(1.0/65536, 30928.0/65536, -1.0/65536, 34608.0/65536)
});

export default {
  name: 'MapView',
  data: () => {
    return {
      mapOptions: {
        fadeAnimation: false
      },
      layerOptions: {
        unloadInvisibleTiles: true
      },
      socket: null,
      url: 'map/{z}/{x}/{y}.png',
      zoom: 3,
      maxZoom: 16,
      noWrap: true,
      center: [0,0],
      worldCopyJump: false,
      crs: ownCRS,
      tms: true,
      attribution: process.env.VUE_APP_TITLE,
      features: [],
      iconPlayer: new L.Icon({
        iconUrl: require('@/assets/marker-player.png'),
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
      }),
    }
  },
  computed: {
    players() {
      return this.$store.state.players;
    },
    ...mapState({
      autoUpdate: state => state.autoUpdate,
      playersLayer: state => state.playersLayer,
    }),
  },
  methods: {
    update(evt) {
      this.$store.commit('updateCoordinates', [evt.latlng.lat, evt.latlng.lng])
    },
    flipCoordinates(pos) {
      return [pos[1], pos[0]];
    }
  },
}
</script>

<style>
.leaflet-touch .leaflet-bar a {
  color: #f5f5f5;
  background-color: #424242;
}
</style>
